import React from 'react'
import { Box, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const jsondata = require('../assets/macos_client.json')
const jsondatav2 = require('../assets/macos_client_v2.json')

const PageSize = 25

const renderDownloadLink = (params) => {
    // console.log(params)
    if (params.value) {
        return <a href={"files/macos-client/" + params.value} download>{params.value}</a>
    }
    return ""
}

const renderDownloadLinkV2 = (params) => {
    // console.log(params)
    if (params.value) {
        return <a href={"files/macos-client/v2/" + params.value} download>{params.value}</a>
    }
    return ""
}

const renderNotes = (params) => {
    // console.log(params)
    if (params.value) {
        const text = params.value
        return (
            <Box sx={{ width: '100%', borderRadius: 1, border: '1px solid grey', p: 2 }}>
                <Typography variant="body1" gutterBottom>
                    {text}
                </Typography>
            </Box>
        );
    }
    return ""
}

const columns = [
    { field: 'name', headerName: 'Name', minWidth: 280, renderCell: renderDownloadLink },
    { field: 'version', headerName: 'Version', minWidth: 180 },
    { field: 'size', headerName: 'Size', minWidth: 50, },
    { field: 'latest', headerName: 'Latest', minWidth: 50 },
    { field: 'created', headerName: 'Created', minWidth: 220 },
    { field: 'sha256', headerName: 'SHA256', minWidth: 550 },
];

const columnsv2 = [
    { field: 'name', headerName: 'Name', minWidth: 280, renderCell: renderDownloadLinkV2 },
    { field: 'version', headerName: 'Version', minWidth: 180 },
    { field: 'size', headerName: 'Size', minWidth: 50, },
    { field: 'latest', headerName: 'Latest', minWidth: 50 },
    { field: 'created', headerName: 'Created', minWidth: 220 },
    { field: 'sha256', headerName: 'SHA256', minWidth: 550 },
];

const sortModel = [
    {
        field: 'created', // the name of the field to sort by
        sort: 'desc', // 'desc' or 'asc'
    },
];

const IsLatest = (ver, latestVer) => {
    if (ver === latestVer) {
        return "Yes"
    }
    return ""
}

const ClientApp = () => {

    const imageArray = jsondata.images

    imageArray.forEach((item) => {
        if (IsLatest(item.version, jsondata.latest)) {
            item.latest = 'Yes'
        }
    });

    const [, setPage] = React.useState(1);
    const [rows] = React.useState(jsondata.images);


    const imageArrayV2 = jsondatav2.images

    imageArrayV2.forEach((item) => {
        if (IsLatest(item.version, jsondatav2.latest)) {
            item.latest = 'Yes'
        }
    });

    const [, setPageV2] = React.useState(1);
    const [rowsv2] = React.useState(jsondatav2.images);

    return (
        <Box sx={{ m: 10 }}>
            <Typography variant='h3'>MacOS Client</Typography>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                sortModel={sortModel} // add this line to specify the sort model
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.name}
                pagination
                pageSize={PageSize}
                rowsPerPageOptions={[PageSize]}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
            />
            <Typography variant='h3'>MacOS Client V2 Preview</Typography>
            <DataGrid
                autoHeight
                rows={rowsv2}
                columns={columnsv2}
                sortModel={sortModel} // add this line to specify the sort model
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.name}
                pagination
                pageSize={PageSize}
                rowsPerPageOptions={[PageSize]}
                onPageChange={(newPage) => {
                    setPageV2(newPage);
                }}
            />
        </Box>
    )
}

export default ClientApp